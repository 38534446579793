@import "../../../partials/functions";
@import "../../../partials/variables";
@import "../../../partials/mixins";
.home-page {
  padding-top: 23px;
  .hp-promo-carousel {
    .button.lg {
      &.carousel-cb {
        min-width: 100px;
      }
    }
  }
  .home-promo-static {
    .non-shopper {
      img { @include store-tile; }
    }
  }
}
.home-top-stores {
  li {
    line-height: 30px;
    margin-right: 45px;
    &:last-child { margin-right: 0; }
  }
  .logos {
    a {
      img { padding-top: 2px; }
    }
  }
}
@media all\0{.home-top-stores li{margin-right:43px;}} // ie8 */

.premium-store-top {
  height: 180px;
  .small-banner {
    width: 100%;
    height: 100px;
    overflow: hidden;
    img { margin-left: -1px}
  }
  .logos {
    padding: 10px 24px;
    margin-top: -26px;
  }
}

.home-dd {
  background-color: $white-color;
  margin-bottom: 10px;
  h2 {
    @include border();
    padding: 20px 30px;
    a {
      &.dd-tile {
        overflow: hidden;
      }
    }
  }
  .home-dd-info {
    text-align: center;
    padding: 15px 30px;
    @include border();
    border-top: none;
    .img-blk {
      float: left;
      padding: 5px 0 0px 10px
    }
    .was_rebate {
      display: block;
      font-size: 12px;
      color: $gray-dark-8-color;
    }
  }
  .arr-right { margin-left: 4px; }

}

#dotw-nav .display-slider-nav{width:70px;text-align:center;}
#dotw-nav .display-slider-indicators{display:inline;float:right;height:13px;width:auto;margin:8px 0 0 0;}
#dotw-nav .display-slider-indicators a{background-color:$darker-border-color;display:block;float:left;font-size:1px;height:10px;width:10px;margin:2px;clear:none;border-radius:10px;}
#dotw-nav .display-slider-indicators a.selected{background-color:$brand-01;}
#dotw-nav .display-slider-navigators{margin:6px 10px 0 0;height:16px;width:70px;cursor:pointer;}
#dotw-nav .btn_prev,
#dotw-nav .btn_next{margin:0;padding:0;height:17px;width:17px;}
#dotw-nav .btn_prev{background-position:-118px -37px;background-color:$tert-text-color;}
#dotw-nav .btn_next{background-position:-118px -54px;background-color:$tert-text-color;}
div.trackingimage{height:1px;width:1px;}

.clearfix:after{content:".";display:block;height:0;clear:both;visibility:hidden;}
/* --- Lifecycles --- */
.lifecycles .col3{height:120px;}
.lifecycles .icon{height:40px;}
.lifecycles .info{position:relative;}
.lifecycles .fa-angle-right{position:absolute;top:25px;}
.lifecycles .col3:last-child{margin-right:0;}
.close-video{right:10px;top:10px;}
.lifecycles .fa-angle-right{vertical-align:-2px;position:static;}
.clo-icon{width:28px;height:26px;background-position:-24px -55px;}
.button-icon{width:23px;height:26px;background-position:0px -55px;}
#backgroundPopup{z-index:99999;}
.video-overlay{z-index:100000;}
.tcpp-accept{ left: 100px; margin-top: -100px; position: absolute; text-align: center; top: 45%; width: 85%;
  .ls-half{letter-spacing: 0.5px;}
  .margin-lr{margin-right: 275px;margin-left: 275px;}
}